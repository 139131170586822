<template>
  <auth-card :loading="loggingIn"
    :loading-text="$t('authentication.logging-in')"
    btn-classes="full-width">
    <template #description>
      <el-form ref="loginForm"
        :model="loginForm"
        data-name="login-form"
        :rules="loginRules"
        @submit.native.prevent>
        <fieldset class="auth-fieldset">
          <legend class="text-align-left">
            {{ loginHeading }}
          </legend>

          <p v-if="showHelpText"
            class="text-align-left text-muted">
            {{ $t('authentication.login-sso-help-reset-password') }}
          </p>

          <p v-if="showHelpText"
            class="text-align-left text-muted">
            {{ $t('authentication.login-sso-help-idp-config') }}
          </p>

          <el-form-item :label="$t('general.email')"
            prop="username">
            <el-input ref="loginFormUsername"
              v-model="loginForm.username"
              data-testid="login-username-input"
              name="email"
              type="email"
              autocomplete="email"
              :placeholder="$t('general.email')"
              @keyup.enter.native="loginUser" />
          </el-form-item>

          <el-form-item :label="$t('general.password')"
            prop="password">
            <el-input ref="loginFormPassword"
              v-model="loginForm.password"
              data-testid="login-password-input"
              name="password"
              autofocus
              :type="showPassword ? 'text' : 'password'"
              :placeholder="$t('general.password')"
              @keyup.enter.native="loginUser">
              <span slot="suffix"
                class="cursor-pointer">
                <i v-if="showPassword"
                  class="fa fa-eye mr-1"
                  @click="showPassword = !showPassword" />
                <i v-else
                  class="fa fa-eye-slash mr-1"
                  @click="showPassword = !showPassword" />
              </span>
            </el-input>
          </el-form-item>

          <div class="d-flex justify-content-space-between"
            :class="{ 'no-help-forgot-password': showHelpText }">
            <login-help-link v-if="!showHelpText" />
            <forgot-password-link />
          </div>

          <template v-if="requiredPasswordReset">
            <el-alert class="mt-1">
              {{ $t('authentication.password-reset') }}
            </el-alert>

            <el-form-item :label="$t('authentication.new-password')"
              class="top-label"
              prop="resetPassword">
              <el-input v-model="loginForm.resetPassword"
                data-testid="login-new-password-input"
                :placeholder="$t('authentication.new-password')"
                name="resetPassword"
                type="password"
                autocomplete="off">
                <template #append>
                  <el-popover placement="top"
                    :title="$t('authentication.password-must-include')"
                    width="300"
                    trigger="hover">
                    <div class="text-align-left">
                      <ul class="pl-2">
                        <li>{{ $t('authentication.at-least-8-char') }}</li>
                        <li>{{ $t('authentication.at-least-one-number') }}</li>
                        <li>{{ $t('authentication.at-least-one-symbol') }}</li>
                        <li>{{ $t('authentication.at-least-one-lower-case-letter') }}</li>
                        <li>{{ $t('authentication.at-least-one-upper-case-letter') }}</li>
                      </ul>
                    </div>
                    <span slot="reference"
                      :class="requiredResetPassStrength.class">
                      <i class="el-icon-info color-info"
                        aria-hidden="true" />
                    </span>
                  </el-popover>
                </template>
              </el-input>
            </el-form-item>

            <el-form-item :label="$t('authentication.confirm-new-password')"
              class="top-label"
              prop="confirmResetPassword">
              <el-input v-model="loginForm.confirmResetPassword"
                data-testid="login-confirm-new-password-input"
                :placeholder="$t('authentication.confirm-new-password')"
                name="confirmResetPassword"
                autocomplete="off"
                type="password" />
            </el-form-item>
          </template>

          <template v-if="loginAs">
            <el-form-item :label="$t('authentication.target-username')"
              prop="targetUser"
              class="top-label">
              <el-input v-model="loginForm.targetUsername"
                data-testid="login-as-input"
                name="targetUser"
                autocomplete="on"
                :placeholder="$t('authentication.target-username')"
                @keyup.enter.native="loginUser" />
            </el-form-item>
          </template>
          <div class="mt-2 text-align-left">
            <el-button name="login"
              type="primary"
              class="full-width"
              :disabled="loggingIn"
              data-testid="login-username-pw-button"
              @click="loginUser">
              {{ $t('authentication.continue') }}
            </el-button>

            <signup-link v-if="showHelpText"
              class="mt-1" />

            <i18n v-if="showHelpText"
              path="authentication.login-sso-assistance"
              tag="p"
              class="text-align-left text-muted">
              <template #docs>
                <a href="https://docs.megaport.com/support/contact/"
                  data-testid="login-help-docs-portal-assistance"
                  target="_blank"
                  rel="noopener">{{ $t('authentication.contact') }}</a>
              </template>
            </i18n>

            <h5 class="or">
              <span>{{ $t('authentication.or') }}</span>
            </h5>

            <el-button data-testid="login-user-back-button"
              class="full-width"
              @click="$router.push('/login')">
              {{ $t('general.back') }}
            </el-button>
          </div>
        </fieldset>
      </el-form>
    </template>
  </auth-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { showModalAlert } from '@/components/authentication/AuthHelpers.js'
import { validatePassword } from '@/validators.js'
import { passStrength } from '@/utils/passwordStrength.js'

import AuthCard from '@/components/ui-components/AuthCard.vue'
import LoginHelpLink from '@/components/authentication/login/LoginHelpLink.vue'
import ForgotPasswordLink from '@/components/authentication/login/ForgotPasswordLink.vue'
import SignupLink from '@/components/authentication/signup/SignupLink.vue'

export default {
  name: 'LoginForm',

  components: {
    'auth-card': AuthCard,
    'login-help-link': LoginHelpLink,
    'forgot-password-link': ForgotPasswordLink,
    'signup-link': SignupLink,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  props: {
    loginAs: {
      type: Boolean,
      required: false,
      default: false,
    },
    showHelpText: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPasswordReset: {
      type: Boolean,
      required: false,
      default: false,
    },
    username: {
      type: String,
      required: false,
      default: '',
    },
    targetUsername: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      showPassword: false,
      requiredPasswordReset: this.showPasswordReset || this.$route.query.passwordReset || false,
      loginForm: {
        username: this.username || localStorage.getItem('_username') || '',
        password: '',
        // 2FA
        oneTimePassword: '',
        // Force reset password
        resetPassword: '',
        confirmResetPassword: '',
        // Loginas
        targetUsername: this.targetUsername || this.$route.params.targetUsername || localStorage.getItem('_target_username') || '',
      },
      loginRules: {
        username: [
          { required: true, message: this.$t('validations.required', { thing: 'Email' }), trigger: 'blur' },
          { type: 'email', message: this.$t('validations.email-invalid'), trigger: 'blur' },
        ],
        password: { required: true, message: this.$t('validations.required', { thing: 'Password' }), trigger: 'blur' },
        // 2FA
        oneTimePassword: { required: true, validator: this.validateOneTimePassword, trigger: 'blur' },
        // Force reset password
        resetPassword: [
          { required: true, message: this.$t('validations.required', { thing: 'Password' }), trigger: 'blur' },
          { validator: this.validateRequiredResetPassword, trigger: 'blur' },
        ],
        confirmResetPassword: [
          { required: true, message: this.$t('validations.required', { thing: 'Confirm Password' }), trigger: 'blur' },
          { validator: this.validateConfirmResetPassword, trigger: 'blur' },
        ],
      },
    }
  },

  computed: {
    ...mapState('Services', ['services']),
    ...mapState('Auth', ['loggingIn']),
    ...mapGetters('Auth', ['requiresMfaSetup', 'homePage']),
    loginHeading() {
      return this.showHelpText ? this.$t('authentication.login-help') : this.$t('authentication.login-to-your-account')
    },

    /**
     * Return object containing info on password strength
     */
    requiredResetPassStrength() {
      if (this.loginForm.resetPassword.length && this.loginForm.password === this.loginForm.resetPassword) {
        return {
          rating: this.$t('authentication.very-weak'),
          class: 'color-danger',
          feedback: {
            warning: this.$t('validations.password-different'),
          },
          score: 0,
        }
      }
      return passStrength(this.loginForm.resetPassword)
    },
  },

  created() {
    // we don't want users to end up on this screen without a username, unless attempting to find help or internal via loginAs
    if (!this.loginForm.username && !this.loginAs && !this.showHelpText) this.$router.push('/login')
  },

  methods: {
    ...mapActions('Services', ['getMyServices']),
    ...mapActions('Auth', ['login']),
    ...mapMutations('Auth', ['setLoggingIn']),
    ...mapMutations(['setG2NewLogin']),

    /**
     * Validate reset password field does not match current password
     * @param {Object} rule
     * @param {string} value
     * @param {Function} callback
     */
    validateRequiredResetPassword(rule, value, callback) {
      if (this.requiredPasswordReset) {
        if (value.length === 0) {
          callback(this.$t('validations.new-password'))
        } else if (this.loginForm.password === value) {
          callback(this.$t('validations.password-different'))
        } else {
          const passwordAnalysis = this.requiredResetPassStrength
          validatePassword(rule, value, callback, passwordAnalysis)
        }
      } else {
        callback()
      }
    },

    /**
     * Validate reset password and confirm reset password fields match
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateConfirmResetPassword(_rule, value, callback) {
      if (value !== this.loginForm.resetPassword) {
        callback(new Error(this.$t('validations.confirm-password-mismatch')))
      } else {
        callback()
      }
    },

    loginUser() {
      this.$refs['loginForm'].validate(valid => {
        if (!valid) return
        this.setLoggingIn(true)
        window.newRegister = false

        const loginPayload = {
          username: this.loginForm.username.trim(),
          password: this.loginForm.password,
          resetPassword: this.loginForm.resetPassword ?? null,
        }

        if (this.loginAs) {
          loginPayload.target_username = this.loginForm.targetUsername
        }

        this.login({ payload: loginPayload })
          .then(() => {
            localStorage.setItem('_username', this.loginForm.username || '')
            if (this.loginForm.targetUsername) localStorage.setItem('_target_username', this.loginForm.targetUsername)
            this.completeLogin()
          })
          .catch(failObj => {
            this.setLoggingIn(false)

            if (!failObj.handled) {
              console.error(failObj)

              if (!this.destroyed) {
                let errorMessage = ''
                let errorLevel = 'warning'

                if (failObj.status === 500) {
                  errorMessage = this.$t('general.unexpected-error')
                  errorLevel = 'error'
                } else if (failObj.data?.data === 'RP') {
                  this.requiredPasswordReset = true
                  errorMessage = this.$t('authentication.need-to-reset')
                } else if (failObj.data?.data === '2FA') {
                  this.setLoggingIn(false)
                  this.$router.push('/mfa')
                  return
                } else if (failObj.status === 409) {
                  // User is not verified, redirect to verification page
                  localStorage.setItem('_username', this.loginForm.username || '')
                  this.setLoggingIn(false)
                  return this.$router.push('/verify-email', () => { /* empty */ })
                } else if (failObj.status === 403) {
                  // this is yucky, but with loginas errors on 403 now we just use the api errors (and have no nice way to detect them).
                  // These are internal only so not translated, also should have been cleaner on the API side, shared http response codes
                  // need a simpler method to detect the different errors.
                  if (failObj.data.message.startsWith("You can't impersonate") || failObj.data.message.startsWith('The account of target')) {
                    errorMessage = failObj.data.message
                  } else {
                    errorMessage = this.$t('authentication.sso-enforced-no-access')
                  }

                  errorLevel = 'error'
                } else if (failObj.status === 410) {
                  errorMessage = this.$t('authentication.login-temp-password-expired')
                } else if (failObj.status === 401 || failObj.data?.message) {
                  errorMessage = failObj.data?.message
                  errorLevel = 'error'
                }

                this.$refs.loginFormUsername?.blur()
                this.$refs.loginFormPassword?.blur()
                showModalAlert(errorMessage, this.$t('authentication.failed'), errorLevel)
                this.setLoggingIn(false)
              }
              localStorage.setItem('_username', this.loginForm.username || '')
              if (this.loginForm.targetUsername) localStorage.setItem('_target_username', this.loginForm.targetUsername)
            }
          })
      })
    },
    /**
     * We need to know how many services they have before logging in
     * so as to redirect them to dashboard or services
     */
    async completeLogin() {
      if (this.requiresMfaSetup) {
        this.$router.push('/setup-mfa')
        return
      }

      this.setG2NewLogin(true)
      try {
        await this.getMyServices()
      } finally {
        this.$router.push(this.homePage)
        this.setLoggingIn(false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.el-icon-info {
  font-size: 1.6rem;
  line-height: initial;
}

.el-button + .el-button {
  margin-left: 0;
}

.text-muted {
  color: var(--color-info);
}

.text-underline {
  text-decoration: underline;
}

.no-help-forgot-password {
  justify-content: flex-end !important;
}
</style>
