<template>
  <el-button type="text"
    class="p-0"
    data-testid="login-forgot-password-button"
    @click="$router.push('/forgot-password')">
    {{ $t('authentication.forgot') }}
  </el-button>
</template>

<script>
export default {
  name: 'ForgotPasswordLink',
}
</script>
